/* globals SUPPORTED_BROWSER_VERSIONS */
import BrowserSupport from './utils/BrowserSupport';
import isInAppView from './utils/isInAppView';

const browserSupport = new BrowserSupport(SUPPORTED_BROWSER_VERSIONS);

if (!isInAppView && !browserSupport.isCurrent()) {
  const info = browserSupport.info();
  const heading = `Hinweis: Ihr Internet Browser (${info.name} ${
    info.version
  }) ist veraltet.`;
  const text =
    'Bitte nutzen Sie eine <span>aktuelle Version</span> Ihres Browsers, um den vollen Funktionsumfang von meinereise.meinschiff.com zu erhalten.';

  const browserWarning = document.createElement('div');
  browserWarning.className += ' browserwarning';
  browserWarning.innerHTML = `
            <p class="browserwarning__headline">${heading}</p>
            <p class="browserwarning__text">${text}</p>
            <div id="browserwarning-closer" class="browserwarning__icon">X</div>
    `;
  document.body.insertBefore(browserWarning, document.getElementById('app'));

  const browserWarningIcon = document.getElementById('browserwarning-closer');

  if (browserWarningIcon.attachEvent) {
    browserWarningIcon.attachEvent('onclick', function() {
      browserWarning.className += ' browserwarning--closed';
    });
  } else {
    browserWarningIcon.addEventListener('click', function() {
      browserWarning.className += ' browserwarning--closed';
    });
  }
}
