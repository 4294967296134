// TODO: add flow types

/* globals SUPPORTED_BROWSER_VERSIONS */
import platform from 'platform';

// Example browser list:
// const SUPPORTED_BROWSER_VERSIONS = {
//     'chromemobile': 56,
//     'firefoxmobile': 51,
//     'chrome': 55,
//     'microsoftedge': 13,
//     'firefox': 51,
//     'ie': 11,
//     'ie_mob': 11,
//     'ios_saf': 9,
//     'opera': 42,
//     'safari': 9
// };

export default class BrowserSupport {
  constructor(currentBrowsers) {
    this.currentBrowsers = currentBrowsers || SUPPORTED_BROWSER_VERSIONS;
  }

  isCurrent(uaString) {
    // use given UAString or the default
    const info = this.info(uaString);
    const browserVersion = parseInt(info.version, 10);

    // if we don't know the browser, we dont reject it
    if (!info.name) {
      return true;
    }

    let browserNameLowerCase = info.name.replace(' ', '').toLowerCase();

    // special case for Samsung Internet
    if (browserNameLowerCase === 'samsunginternet') {
      browserNameLowerCase = 'samsung';
    }

    return (
      this.currentBrowsers.hasOwnProperty(browserNameLowerCase) &&
      this.currentBrowsers[browserNameLowerCase] <= browserVersion
    );
  }

  info(uaString) {
    return uaString ? platform.parse(uaString) : platform;
  }
}
